<template>
  <div class="middle">
    <loading :active.sync="isLoading"
             :is-full-page="true"
             :width="125"
             :height="125"
             :color="spinnerColor"
             :opacity="0"
             :background-color="backgroundColor"></loading>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

export default {
  name: 'LoadingOverlay',
  data() {
    return {
      isLoading: true,
      fullPage: true,
      spinnerColor: '#1f9ed3',
      backgroundColor: '000000',
    };
  },
  components: {
    Loading,
  },
};
</script>

<style lang="scss" scoped>
  .middle {
    height: 100vh;
    margin-top: 35vh;
  }
</style>
